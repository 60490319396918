@import 'styles/includes.scss';

.FactBox {
    @extend %rich-text;
    background-color: $colorBlue50;
    padding: 16px;
    margin-bottom: 32px;

    &--Purple50 {
        background-color: $colorPurple50;
    }

    &--Green50 {
        background-color: $colorGreen50;
    }

    &--Blue50 {
        background-color: $colorBlue50;
    }

    &--Orange50 {
        background-color: $colorOrange50;
    }

    @include media(m) {
        padding: 32px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        padding-top: 0;
    }

    p {
        max-width: unset;
        word-break: break-word;
    }

    *:last-child {
        margin-bottom: 0;
    }
}
